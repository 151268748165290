import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/Header6';
import Footer from './../Layout/Footer1';
import PageTitle from './../Layout/PageTitle';

//Images
import  about9 from './../../images/career.jpg';
import bg2 from './../../images/background/bg-map.jpg';
import services1 from './../../images/our-services/mission.jpg';
import services2 from './../../images/our-services/vision.jpg';

import bnr from './../../images/banner/bnr2.jpg';

const wraperBox4 = [
	{icon: <i className="ti-desktop" />, description: "Create effective teaching and learning experiences for all students and adjust teaching to meet students' individual needs and diverse cultural, social and linguistic characteristics.",},
	{icon: <i className="ti-ruler-pencil" />, description: "Develop safe, positive and productive learning environments where all students are encouraged to participate. ",},
	{icon: <i className="ti-palette" />, description: 'Design and implement engaging teaching programs that meet curriculum, assessment and reporting requirements. ',},
	{icon: <i className="ti-file" />, description: 'Use feedback and assessment to inform planning and evaluate your own teaching regularly to adjust learning programs to better meet student needs .',},
	{icon: <i className="ti-file" />, description: 'Actively participate in the teaching profession and identify, plan and evaluate own professional learning needs. Involved in School Performance and Development Framework. ',},
	{icon: <i className="ti-file" />, description: "Work collaboratively with colleagues and communicate effectively with students, colleagues, parents/carers and community members and develop the tri-partnership of teacher, student, and parent in accordance with the school's ethos. ",},
];

class JobOpportunities extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='Job Opportunities' activeMenu='Job Opportunities' />
					</div>	
                    <div className="content-block">
                        <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h4 className="text-gray-dark m-b10">Job Opportunities</h4>
                                    <h2 className="box-title m-tb0">WE ARE HIRING</h2>
                                    <p>For Local and Overseas applicants from UK, Canada, South Africa and NZ.</p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row ">
                                    <div className="col-lg-5 col-md-4 about-img">
                                        <img src={about9} data-tilt alt="Job Opportunities" />
                                    </div>
                                    <div className="col-lg-7 col-md-8">
                                        <div className="abuot-box left row m-lr0 ">
                                            <div className="col-lg-12">
                                                <h2 className="box-title m-b10">Seeking world-class Educators with Full Time Positions for:</h2>                                                
                                                <ul className='list-checked primary'>
                                                    <li>Primary teachers</li>
                                                    <li>Casual High School Teachers</li>
                                                    <li>Multiple subjects</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
						<div className="section-full">
                            <div className="container-fluid">
                                <div className="row dzseth">
                                    <div className="col-lg-6 col-md-12 p-a0 img-fix">
                                        <div className="max-w500 m-auto p-tb30">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h3 className="box-title m-b10">KEY RESPONSIBILITIES <span className="bg-primary"></span></h3>
                                                </div>
                                            </div>
                                            <div className="row serv">
                                                {wraperBox4.map((item,index)=>(
                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                        <div className="icon-bx-wraper left p-tb10">
                                                            <div className="icon-bx-xs bg-primary radius m-b20">
                                                                <Link to={"#"} className="icon-cell">{item.icon}</Link>
                                                            </div>
                                                            <div className="icon-content">
                                                                <p className="font-weight-400">{item.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 bg-black-light p-lr0 dis-tbl">
                                        <div className="p-a30 dis-tbl-cell ">
                                            <div className="max-w500 m-auto p-tb30">
                                                <div className="row">
                                                    <div className="col-lg-12 text-white">
                                                        <h3 className="box-title m-b10">SPECIFIC SELECTION CRITERIA<span className="bg-primary"></span></h3>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 m-b30 chosesus-content">
                                                        <ul className="list-checked primary text-white">
                                                            <li><span>Qualified to teach in NSW Primary schools and possess relevant NSW teacher accreditation status (Provisional/ Conditional/ Proficient or higher).</span></li>
                                                            <li><span>Demonstrated ability to deliver high quality differentiated teaching and learning programs embedding Information and Communication Technology.</span></li>
                                                            <li><span>Highly developed communication, organisational and interpersonal skills with a proven ability to build and maintain effective partnerships with students teachers and parents.</span></li>
                                                            <li><span>Demonstrated ability to develop positive learning environments with a thorough understanding of effective behaviour management strategies that enhance student wellbeing and learning outcomes.</span></li>
                                                            <li><span>Demonstrated ability to support the ethos, principles and practices of the College and willingness and enthusiasm to assist with the implementation an organisation of extracurricular activities.</span></li>
                                                            <li><span>Applications will only be considered from applicants who have the right to work in Australia, appropriate NESA accreditation and retention of a valid Working with Children Check.</span></li>
                                                        </ul>                                                        
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-full add-to-call bg-yellow p-tb10">
                            <div className="container">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <h2 className="m-b10 m-t10 text-white">CONTACT US</h2>
                                        <h5 className="dlab-tilte">
                                            <span className="icon-sm text-primary text-left"><i className="ti-mobile"></i></span>
                                            0411 261 597
                                        </h5>
                                    </div>
                                    <div className='col-md-6'>
                                        <h2 className="m-b10 m-t10 text-white">Email</h2>
                                        <h5 className="dlab-tilte">
                                            <span className="icon-sm text-primary text-left"><i className="ti-email"></i></span>
                                            enquiries@illuminateaust.org.au
                                        </h5>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
function VisionBlog(){
	return(
		<>
			<div className="section-ful our-about-info content-inner-1 " style={{ backgroundImage: "url(" + bg2 + ")",backgroundPosition:"center" ,backgroundRepeat:"no-repeat" }}>
				<div className="container">
					<div className="section-head text-center">
						<h2 className="box-title m-tb0">Mission And Vision<span className="bg-primary"></span></h2>
						<p>To establish Independent Not for Profit Shia Schools in all major cities of Australia </p>
					</div>
					<div className=" row dzseth  m-b30">
						<div className="col-lg-6 col-md-12 m-b30 about-img ">
							<img src={services1} data-tilt alt="" />
						</div>
						<div className="col-lg-6 col-md-12 m-b30 dis-tbl text-justify">
							<div className="dis-tbl-cell">
								<h3 className="box-title">Mision<span className="bg-primary"></span></h3>
								<p className="font-16">We strive to better our students through educational experiences both in and out of the classroom. The mission of Illuminate Australia is to create a place where students feel safe to tackle obstacles and accomplish all of their goals. We offer a variety of opportunities to help students be the best version of themselves, while having some fun along the way.</p>
                                <p className="font-16">By providing a Shia friendly environment for the school aged Shia Muslims where they can learn from and act in accordance to the two invaluable weights namely the Holy Quran and the family of the holy prophet pbuh.</p>
							</div>
						</div>
					</div>
					<div className="row dzseth column-reverse">
						<div className="col-lg-6 col-md-12 dis-tbl text-justify">
							<div className="dis-tbl-cell">
								<h3 className="box-title">Vision<span className="bg-primary"></span></h3>
								<p className="font-16">We want to raise a generation of morally upright students who can contribute to the wider Australian society with the two wings of faith (Iman) and knowledge (I’lm).</p>								
							</div>
						</div>
						<div className="col-lg-6 col-md-12 about-img ">
							<img src={services2} data-tilt alt="" />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}	

export {VisionBlog};
export default JobOpportunities;