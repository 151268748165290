import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/Header6';
import Footer from './../Layout/Footer1';
import PageTitle from './../Layout/PageTitle';
import Sidebar from '../Element/Sidebar';

import bnr from './../../images/banner/bnr11.jpg';
import thum1 from './../../images/events/fundraising-dinner.jpg';

import { FacebookShareButton, TwitterShareButton } from "react-share";

import HelmetMetaData from '../Layout/HelmetMetaData';

const metadata={
    title: "Fundraising Dinner 2024",
    description: "One of the best forms of Sadaqah Jariyah is to contribute to the education of the future generations of our community. This will, insha Allah, count as a continuous form of charity for anyone who takes up this opportunity.",
};
console.log(window.location.href);
class Fundraising extends Component {
    render() {
        return (
            <>
                <HelmetMetaData title={metadata.title} description={metadata.description} image={thum1} />
                <Header />

                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='Fundraising Dinner 2024' activeMenu='Fundraising Dinner 2024' />
                    </div>
                    <div className="content-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-xl-9">
                                    <div className="blog-post blog-single">
                                        <div className="dlab-post-title ">
                                            <h4 className="post-title m-t0">Fundraising Dinner 2024</h4>
                                        </div>
                                        <div className="dlab-post-meta m-b20">
                                            <ul className="d-flex align-items-center">
                                                <li className="post-date"> <i className="fa fa-calendar"></i><strong>04 Feb</strong> <span> 2024</span> </li>
                                                <li className="post-author"><i className="fa fa-user"></i>By<Link to={"#"}> Admin</Link> </li>
                                            </ul>
                                        </div>
                                        <div className="dlab-post-media dlab-img-effect zoom-slow">
											<Link to={"#"}><img src={thum1} alt="" /></Link>
										</div>
                                        <div className="dlab-post-text">
                                            <p>"وَأَقْرِضُوا اللَّهَ قَرْضًا حَسَنًا وَمَا تُقَدِّمُوا لِأَنفُسِكُم مِّنْ خَيْرٍ تَجِدُوهُ عِندَ اللَّهِ هُوَ خَيْرًا وَأَعْظَمَ أَجْرًا"</p>
                                            <p>"...and lend to Allah a goodly loan, and whatever good you send before you for yourselves, you will certainly find it with Allah, better and greater in reward..." (The Holy Quran, 73:20) </p>
                                            <p>One of the best forms of Sadaqah Jariyah is to contribute to the education of the future generations of our community. This will, insha Allah, count as a continuous form of charity for anyone who takes up this opportunity.</p>
                                            <p>The vision of Illuminate Australia is to provide Muslim children with the opportunity to receive a high quality education within an environment that is inspired and instructed by Shia Muslim values and culture.</p>
                                            <p>In line with this vision, we are excited to announce the wonderful news of three new Shia Islamic School projects. </p>
                                            <p>Join us to partake in the mission of building a fruitful legacy for the future generations of our community.</p>
                                        </div>
                                        <div className="dlab-post-tags clear">
                                            <div className="post-tags">
												<Link to={"#"}>Child </Link> 
												<Link to={"#"}>Eduction </Link> 
												<Link to={"#"}>Money </Link>
												<Link to={"#"}>Fundraising </Link> 
											</div>
                                        </div>
                                        <div className="dlab-divider bg-gray-dark op4"><i className="icon-dot c-square"></i></div>
                                        
                                        <div className="share-details-btn">
                                            <ul>
                                                <li><h5 className="m-a0">Share Post</h5></li>
                                                <li>
                                                <FacebookShareButton
                                                    url={"https://illuminateaust.org.au/events/fundraising-dinner-04-feb-2024"}
                                                    quote={"My First Event"}
                                                    hashtag={"#fundraising"}
                                                    description={"Ramesh"}
                                                    className="noclass site-button facebook button-sm"
                                                >
                                                    <i className="fa fa-facebook"></i> Facebook
                                                </FacebookShareButton>
                                                </li>
                                                <li><Link to={"#"} className="site-button google-plus button-sm"><i className="fa fa-google-plus"></i> Google Plus</Link></li>
                                                <li><Link to={"#"} className="site-button linkedin button-sm"><i className="fa fa-linkedin"></i> Linkedin</Link></li>
                                                <li><Link to={"#"} className="site-button instagram button-sm"><i className="fa fa-instagram"></i> Instagram</Link></li>
                                                <li><Link to={"#"} className="site-button twitter button-sm"><i className="fa fa-twitter"></i> Twitter</Link></li>
                                                <li><Link to={"#"} className="site-button whatsapp button-sm"><i className="fa fa-whatsapp"></i> Whatsapp</Link></li>
                                            </ul>
                                        </div>
                                    </div>                                    
                                </div>
                                <div className="col-lg-4 col-xl-3 sticky-top">
                                    <Sidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        )
    }
}
export default Fundraising;