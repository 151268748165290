import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import {SRLWrapper, useLightbox} from 'simple-react-lightbox'; 
import Header6 from './../Layout/Header6';
import Footer1 from './../Layout/Footer1';
import Index4Tab from './../Element/Index4Tab';
import {VideoPopup2} from './../Element/VideoPopup';
import VidoSection from './../Element/VidoSection'

//Images..
import logo from './../../images/logo/center-logo.png';
import piler from './../../images/3cs.jpg';
import bgimg from './../../images/banner/banner.jpg';
import bg16 from './../../images/background/bg16.jpg';
import about1 from './../../images/about/about.png';
import gallery10 from './../../images/project/pic1-1.jpg';
import gallery11 from './../../images/project/pic3-3.jpg';
import gallery12 from './../../images/project/pic4-4.jpg';
import gallery13 from './../../images/project/pic2-2.jpg';
import EventsGrid from '../Element/EventsGrid';
import HelmetMetaData from '../Layout/HelmetMetaData';

//Light Gallery on icon click 
const Iconimage = props => {
	const { openLightbox } = useLightbox()
	return (
    <Link  to={"#"} onClick={() => openLightbox(props.imageToOpen)} className="mfp-link" >
      <i className="ti-fullscreen icon-bx-xs"></i>
    </Link>
  )
}


class Index4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            message: '',
            buttonText: 'Send Query',
            disabled: false,
            showSuccessMessage: false,
            showFailureMessage: false,
            set: false,
            returnMsg: '',
            errors: ''
        }
    }
    onNameChange(event) {
        this.setState({name: event.target.value})
    }
    onEmailChange(event) {
        this.setState({email: event.target.value})
    }
    onPhoneChange(event) {
        this.setState({phone: event.target.value})
    }
    onMessageChange(event) {
        this.setState({message: event.target.value})
    }
    
    handleValidation = () => {
        let tempErrors = {};
        let isValid = true;
    
        if (this.state.name.length <= 0) {
          tempErrors["name"] = true;
          isValid = false;
        }
        if (this.state.phone.length <= 0) {
            tempErrors["phone"] = true;
            isValid = false;
        }
        if (this.state.email.length <= 0) {
          tempErrors["email"] = true;
          isValid = false;
        }
        if (this.state.message.length <= 0) {
          tempErrors["message"] = true;
          isValid = false;
        }
        this.setState({errors: tempErrors})        
        return isValid;
      };

    handleSubmit(event){
        event.preventDefault();        
        let isValidForm= this.handleValidation();
        if (isValidForm) {
            this.setState({buttonText: "Sending Query..."});
            this.state.disabled=true;
            fetch("https://demo.xpansion.com.au/api/illuminate/contact-home.php", {
              body: JSON.stringify({
                email: this.state.email,
                name: this.state.name,
                phone: this.state.phone,
                comments: this.state.message,
              }),
              method: "POST",
            }).then((respose) => {                
              if (respose.ok) {
                return respose.json();
              }
              throw new Error('error')
            })
            .then((result) => {                
              if (result.success) {
                console.log(result);
                  this.state.name="";
                  this.state.email="";
                  this.state.phone="";
                  this.state.message="";
                  this.state.showSuccessMessage=true;
                  this.state.showFailureMessage=false;          
              } else {
                  this.state.showSuccessMessage=false;
                  this.state.showFailureMessage=true;          
              }
              this.state.disabled=false;
              this.setState({buttonText: "Send Query"});              
              this.setState({returnMsg: result.message});
            });
        }
    }


    componentDidMount() {
        var i = 0;
		
		// Placeholder Animation Start
		var inputSelector = document.querySelectorAll('input, textarea');
		
		for (i = 0; i < inputSelector.length; i++) {
			inputSelector[i].addEventListener('focus', function(event) {
				return this.parentElement.parentElement.classList.add("focused");
			});
		}
				
		for (i = 0; i < inputSelector.length; i++) {
			inputSelector[i].addEventListener('blur', function(event) {
				var inputValue = this.value;	
				if ( inputValue === '' ) {
					this.parentElement.parentElement.classList.remove('filled');
					this.parentElement.parentElement.classList.remove('focused');  
				} else {
					this.parentElement.parentElement.classList.add('filled');
				}
			});
		}
    }
    render() {
        return (
            <>
                <HelmetMetaData/>
                <Header6 />
                <div className="page-content bg-white rubik">
					<div className="home-banner" style={{backgroundImage:"url("+ bgimg +")", backgroundSize:"contain" }}>
						<div className="home-bnr-inner">
							<div className="home-bnr-content">
                                <img src={logo} className='centerlogo' alt='Logo'/>
								<h4 className="sub-title">ILLUMINATE</h4>
								<h2 className="dz-title">AUSTRALIA</h2>
								<div className="home-bnr-btns">
									<Link to={"./about"} className="site-button white btn-icon">Read more <i className="fa fa-angle-double-right"></i></Link>
									<VideoPopup2 />
								</div>
							</div>	
						</div>
					</div>

                    {/* <!-- contact area --> */}
                    <div className="content-block">
                        {/* <!-- About Us --> */}
                        <div className="section-full content-inner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 col-lg-12 text-center">
                                        <h2 className="text-uppercase mb-0"><span className="font-weight-300">Welcome To </span></h2>
                                        <h1 className='text-uppercase font-55 font-weight-700'>Illuminate Australia</h1>
                                    </div>
                                    <div className="col-md-5 d-flex order-md-2 ">
                                        <div className="img-half-bx align-items-stretch">
                                            <img src={about1}  alt="About Us" />
                                        </div>
                                    </div>
                                    <div className="col-md-7 order-md-1">                                        
                                        <p className='font-16'>The vision of Illuminate Australia is to provide Muslim children with the opportunity to receive a high quality education in an environment that is inspired and instructed by Shia Muslim values and culture. </p>
                                        <p className='font-16'>We want our community to benefit from successive generations of morally upright students who can contribute to the wider Australian society with the two wings of faith (Iman) and knowledge (I’lm).</p>
                                        <p className='font-16'>We estimate that there are 25,000 Shia Muslim school aged children across Australia. Our mission is to provide as many of these students with easy access to such a schooling environment by establishing independent not-for-profit, faith-based schools aligned with Islamic values in all the major cities in Australia.</p>
                                    </div>                               
                                </div>
                            </div>
                        </div>                        
                        <div className="section-full content-inner-2 tab-bx br-top">
                            <div className="container">
                                <div className='row'>
                                    <div className='col-md-6 mb-4'>
                                        <img src={piler}  alt="3C's" />
                                    </div>
                                    <div className='col-md-6'>
                                        <h2 className="text-uppercase"><span className="font-weight-300">Our</span> Blueprint</h2>
                                        <p className="">At Illuminate Australia, we've nurtured a visionary prototype, which has been developed through a process of application, reflection and refinement at our schools.</p>
                                        <p className="">The Illuminations Program aims to create a sanctuary of enlightenment for the students, inspired by the ocean of Islamic wisdom and knowledge. </p>
                                        <p>The 15-year journey has led to the concept of the IHSAN department, which facilitates a whole-school approach to instilling Islamic values in the students. </p>
                                        <p>The IHSAN department is a good tree with three branches, known as the 3Cs: Character Taught, Character caught, and Community Engagement.</p>
                                    </div>
                                </div>
                                <div className="">
                                    <Index4Tab />
                                </div>
                            </div>
                        </div>
                        {/* <div className="section-full content-inner-2 bg-img-fix overlay-primary gradient testimonial-curv-bx" style={{ backgroundImage: "url(" + bgimg + ")" }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-8">
                                        <div className="section-head text-center">
                                            <h2 className="text-uppercase"><span className="font-weight-300">Happy customers</span> <br /> Our Reviews</h2>
                                            <h5 className="font-weight-300 text-gray-dark">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</h5>
                                        </div>
                                        <ReviewsCarousel />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <VidoSection />
                        <div className="section-full content-inner bg-white workspace-bx mfp-gallery">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-head text-center">
                                            <h2 className="text-uppercase "><span className="font-weight-300">Check our recent projects</span> <br />Our Work</h2>
                                        </div>
                                    </div>
                                </div>
								<SimpleReactLightbox>
									<SRLWrapper >
										<div className="row">
											<div className="col-lg-6 col-md-12 col-sm-12">
												<div className="dlab-box portfolio-box m-b30">
													<div className="dlab-media dlab-img-effect dlab-img-overlay1" data-tilt=""> <img src={gallery10} alt="" />
														<div className="overlay-bx">
															<div className="overlay-icon text-white">
																<h5>Bellfield College</h5>
																<p className="m-b10">The College promotes the pursuit of academic excellence and social responsibility.</p>
																<Iconimage />
																<a href="https://www.bellfield.nsw.edu.au/" target="bank"><i className="ti-arrow-top-right icon-bx-xs"></i></a>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-6 col-md-12 col-sm-12">
												<div className="row">
													<div className="col-lg-6 col-md-6 col-sm-6">
														<div className="dlab-box portfolio-box m-b30">
															<div className="dlab-media dlab-img-effect dlab-img-overlay1" data-tilt=""> <img src={gallery11} alt="" />
																<div className="overlay-bx">
																	<div className="overlay-icon text-white">
																		<h5>Mooroopna College</h5>
																		<p className="m-b10">Upcoming College, Starting soon in 2026.</p>
																		<Iconimage />
																		<a href="#" target="bank"><i className="ti-arrow-top-right icon-bx-xs"></i></a>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="col-lg-6 col-md-6 col-sm-6">
														<div className="dlab-box portfolio-box m-b30">
															<div className="dlab-media dlab-img-effect dlab-img-overlay1" data-tilt=""> <img src={gallery12} alt="" />
																<div className="overlay-bx">
																	<div className="overlay-icon text-white">
																		<h5>Upcoming College in Perth</h5>
																		<p className="m-b10">Launching soon in Perth.</p>
																		<Iconimage />
																		<a href="#" target="bank"><i className="ti-arrow-top-right icon-bx-xs"></i></a>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="col-lg-12 col-md-12 col-sm-12">
														<div className="dlab-box portfolio-box m-b30">
															<div className="dlab-media dlab-img-effect dlab-img-overlay1" data-tilt=""> <img src={gallery13} alt="" />
																<div className="overlay-bx">
																	<div className="overlay-icon text-white">
																		<h5>Playford College</h5>
																		<p className="m-b10">Playford College introduced a Middle School for Year 6 to Year 9 students in 2019.</p>
																		<Iconimage />
																		<a href="https://playfordcollege.sa.edu.au/" target="bank"><i className="ti-arrow-top-right icon-bx-xs"></i></a>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>										
									</SRLWrapper >
								</SimpleReactLightbox>
                            </div>
                        </div>
                        <EventsGrid />
                        <div className="section-full content-inner-2 bg-img-fix overlay-primary gradient subscribe-bx" style={{ backgroundImage: "url(" + bgimg + ")" }}>
                            <div className="container">
                                <form className="row text-white dezPlaceAni align-items-center dzSubscribe" action="script/mailchamp.php" method="post">
                                    <div className="col-lg-4  col-md-12">
                                        <h2 className="m-b0"><span className="font-weight-300">Subscribe to our</span><br /> Newsletter</h2>
                                    </div>
                                    <div className="col-lg-6 col-md-9 contact-form-bx">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <label>Your Email Address</label>
                                                <input name="dzEmail" type="email" className="form-control" placeholder="" />
                                                <div className="dzSubscribeMsg"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3">
                                        <button name="submit" value="Submit" type="submit" className="site-button button-md radius-xl white btn-block">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="section-full content-inner-2 bg-white contact-form-bx" style={{ backgroundImage: "url(" + bg16 + ")" , backgroundSize: "100%" }}>
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="text-uppercase"><span className="font-weight-300">Contact </span> us</h2>
                                </div>
                                <div className="dezPlaceAni">
                                    <div className="dzFormMsg"></div>
                                    <form method="post" className="" onSubmit={this.handleSubmit.bind(this)} >
                                        <input type="hidden" value="Contact" name="dzToDo" />
                                        <div className="row">
                                            <div className="col-lg-4 col-md-5 col-sm-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <label>Your Name</label>
                                                        <input name="name" value={this.state.name} type="text" className="form-control" placeholder="" onChange={this.onNameChange.bind(this)} />
                                                    </div>
                                                    {this.state.errors?.name && (
                                                        <p className="text-red">Please Enter Name.</p>
                                                    )}
                                                </div>
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <label>Your Email Address</label>
                                                        <input name="email" type="email" value={this.state.email} className="form-control" placeholder="" onChange={this.onEmailChange.bind(this)} />
                                                    </div>
                                                    {this.state.errors?.email && (
                                                        <p className="text-red">Please Enter Email.</p>
                                                    )}
                                                </div>
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <label>Phone</label>
                                                        <input name="phone" type="text" value={this.state.phone} className="form-control" placeholder="" onChange={this.onPhoneChange.bind(this)} />
                                                    </div>
                                                    {this.state.errors?.phone && (
                                                        <p className="text-red">Please Enter Phone.</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-7 col-sm-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <label>Your Message...</label>
                                                        <textarea name="message" rows="4" className="form-control" placeholder="" value={this.state.message} onChange={this.onMessageChange.bind(this)}></textarea>
                                                    </div>
                                                    {this.state.errors?.message && (
                                                        <p className="text-red">Please Enter Message.</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                                <div className="text-left">
                                                    {this.state.showSuccessMessage && (
                                                    <p className="text-green-500 font-semibold text-sm my-2">
                                                        {this.state.returnMsg}
                                                    </p>
                                                    )}
                                                    {this.state.showFailureMessage && (
                                                    <p className="text-red">
                                                        {this.state.returnMsg}
                                                    </p>
                                                    )}
                                                </div>
                                                <button name="submit" type="submit" value="Submit" disabled={this.state.disabled} className="site-button outline outline-1 radius-xl button-md m-t10">{this.state.buttonText}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer1 />
            </>
        )
    }
}
export default Index4;