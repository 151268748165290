import React, { Component } from 'react';
import Header from './../Layout/Header6';
import Footer from './../Layout/Footer1';
import PageTitle from './../Layout/PageTitle';

//Images
import  globe from './../../images/globe.png';
import bg1 from './../../images/background/bg1.png';

import bnr from './../../images/banner/bnr2.jpg';

class Statistics extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='Statistics' activeMenu='Statistics' />
					</div>	
                    <div className="content-block">
                        <div className="section-full bg-white content-inner" style={{ backgroundImage: "url(" + bg1 + ")" }}>                        
                            <div className="container">
                                <div className="our-featured">
                                    <div className="setResizeMargin">
                                        <div className="row m-a0 d-flex align-items-center">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="section-head title-bx">
                                                    <h2>Current Situation</h2>
                                                    <ul className='list-checked primary'>
                                                        <li>Islam has grown to 813,392 people, which is 3.2 per cent of the Australian population. <a href='https://www.abs.gov.au/media-centre/media-releases/2021-census-shows-changes-australias-religious-diversity' target='_blank' rel="noreferrer"> (2021 Census shows changes in Australia’s religious diversity | Australian Bureau of Statistics (abs.gov.au))</a></li>
                                                        <li>About 18.3% of the Muslim population are between the ages of 5-14 and 16.1% between the ages of 15-24. So, at least 20% of the Muslim population are at school age. <a href='https://www.unisa.edu.au/contentassets/4f85e84d01014997a99bb4f89ba32488/australian-muslims-final-report-web-nov-26.pdf' target='_blank' rel="noreferrer">(australian-muslims-final-report-web-nov-26.pdf (unisa.edu.au))</a></li>
                                                        <li>Shia Muslims comprise about 10-20% of Muslim population worldwide. <a href='https://www.pewresearch.org/religion/2009/10/07/mapping-the-global-muslim-population/' target='_blank' rel="noreferrer">(Mapping the Global Muslim Population | Pew Research Center)</a></li>
                                                        <li>Assuming about 15% of Muslims in Australia are Shia Muslims, the Shia population will be as following:</li>
                                                    </ul>                                                    
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 text-right">
                                                <div className="feature-img-bx" >
                                                    <img src={globe} alt="" className="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                            </div>                            
                        </div>
                        <div className='section'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='table-responsive'>
                                            <table className='table table-bordered'>
                                                <thead className='thead-dark'>
                                                    <tr>
                                                        <td>Total Population of Muslims</td>
                                                        <td>Total Population of Muslims</td>
                                                        <td>Muslim population at school age (20%) <br/>Total=162,678</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className='bg-danger'>
                                                        <td>Shia Muslims(15%)</td>
                                                        <td>122,008</td>
                                                        <td>24,402</td>
                                                    </tr>
                                                    <tr>
                                                        <td>NSW Muslims</td>
                                                        <td>361,146 (44.4%)</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr className='bg-success'>
                                                        <td>NSW Shia Muslims</td>
                                                        <td>54,172</td>
                                                        <td>10,834</td>
                                                    </tr>
                                                    <tr>
                                                        <td>VIC Muslims</td>
                                                        <td>2651,166 (32.6%)</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr className='bg-info'>
                                                        <td>VIC Shia Muslims</td>
                                                        <td>39,775</td>	
                                                        <td>7,955</td>
                                                    </tr>
                                                    <tr>
                                                        <td>QLD Muslims</td>
                                                        <td>60,191 (7.4%)</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr className='bg-yellow'>
                                                        <td>QLD Shia Muslims</td>
                                                        <td>9,029</td>
                                                        <td>1,806</td>
                                                    </tr>
                                                    <tr>
                                                        <td>SA Muslim</td>
                                                        <td>38,229 (4.7%)</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr className='bg-green'>
                                                        <td>SA Shia Muslims</td>
                                                        <td>5,734</td>
                                                        <td>1,147</td>
                                                    </tr>
                                                    <tr>
                                                        <td>WA Muslims	68,325</td>
                                                        <td>(8.4%)</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr className='bg-pink'>
                                                        <td>WA Shia Muslims</td>
                                                        <td>10,249</td>
                                                        <td>2,050</td>
                                                    </tr>
                                                    <tr>
                                                        <td>ACT Muslims</td>
                                                        <td>1,301 (1.6%)</td>
                                                        <td>260</td>
                                                    </tr>
                                                    <tr className='bg-orange'>
                                                        <td>ACT Shia Muslims</td>
                                                        <td>195</td>
                                                        <td>39</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default Statistics;