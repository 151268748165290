import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';

import mp4 from '../../videos/sadaqah-jariyah.mp4'
import bg17 from './../../images/background/bg17.jpg'; 
import about11 from './../../videos/sadaqah-jariyah.jpg';
 
const VidoSection = () => {
    const [isOpen, setOpen] = useState(false)

    return (
        <React.Fragment>
            <ModalVideo channel='custom' autoplay isOpen={isOpen} url={mp4} onClose={() => setOpen(false)} />
            <div className="section-full p-t80 p-b80" style={{ backgroundImage: "url(" + bg17 + ")" }}>
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6 col-md-12 m-b30">
                            <h2 className="box-title m-t0 m-b20 font-40"><span className="font-weight-400">Contributing to the </span><br />Education of Future</h2>
                            <p>Contributing to the education of future generations within our community is a profound act of Sadaqah Jariyah. Insha Allah, this becomes an ongoing charity for those who seize this opportunity.</p>
                            <p>Illuminate Australia envisions providing Muslim children with a high-quality education rooted in Shia Muslim values and culture. We are thrilled to announce three new projects for Shia Islamic Schools.</p>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <img src={about11} className="radius-sm" alt="" />
                            <button className="site-button position1 white btn-icon popup-youtube video" onClick={()=> setOpen(true)}  >Watch<i className="fa fa-angle-double-right" ></i></button>
                        </div>
                    </div>
                </div>
            </div>            
        </React.Fragment>
    )
}

export default VidoSection;