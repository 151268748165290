import React, { Component } from 'react';
import Header from './../Layout/Header6';
import Footer from './../Layout/Footer1';
import PageTitle from './../Layout/PageTitle';

import bnr from './../../images/banner/team-bcm.jpg';
import pic1 from './../../images/our-team/dr-hassan-lami.jpg';
import pic2 from './../../images/our-team/ali-hossein.jpg';
import pic3 from './../../images/our-team/dr-mohammad-qais-hassan.jpg';
import pic4 from './../../images/our-team/mustafa-alnadaf.jpg';
import pic5 from './../../images/our-team/dr-hamed-faizi.jpg';


class Team extends Component {
    render() {
        return (
            <>
                <Header />

                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr + ")" }}>
                        <PageTitle motherMenu='Our People' activeMenu='Our People'  />
                    </div>
                    <div className="content-block">
                        <div className="section-full text-center bg-white content-inner-1">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2>Meet Our People</h2>
                                </div>                                
                                <div className="row dzseth  m-b30">
                                    <div className="col-lg-3 col-md-3 m-b30 about-img ">
                                        <img src={pic2} data-tilt alt="Ali Hossein" />
                                    </div>
                                    <div className="col-lg-9 col-md-9 m-b30 dis-tbl text-justify">
                                        <div className="dis-tbl-cell">
                                            <h3 className="box-title m-b0">Ali Hossein<span className="bg-primary"></span></h3>
                                            <p className='font-15 font-weight-600 text-primary'>Founder</p>
                                            <p className="font-16">With over 35 years of multifaceted experience, I am a seasoned professional specialising in accounting, marketing, finance, trade, and education. My career spans a wide array of sectors, including consumer and commercial finance, corporate finance, and community-based non-profit organisations. I have played a pivotal role as a founding member of private schools in New South Wales and South Australia, demonstrating my commitment to educational development.</p>
                                            <p className="font-16">In the realm of education, I have excelled as an advisor, focusing on governance, business models, and institutional development. My expertise is not just confined to educational administration; I have also been instrumental in providing specialised transportation services for individuals with disabilities.</p>
                                            <p className="font-16">I pride myself on being a results-driven leader, recognised for my professionalism and commitment to excellence. My ability to effectively communicate and collaborate with executives, team members, and customers has been a cornerstone of my career. My core competencies include strategic planning in Business, Marketing, Networking, Operations, and Future Planning. Additionally, I have a proven track record in Corporate Performance Management, Revenue Cycle Business Intelligence, and Revenue Cycle Management. These skills, combined with my ability to develop and implement the best management practices, have significantly contributed to improving operational outcomes and leading successful programs.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row dzseth  m-b30">
                                    <div className="col-lg-3 col-md-3 m-b30 about-img ">
                                        <img src={pic1} data-tilt alt="Dr. Hassan Lami" />
                                    </div>
                                    <div className="col-lg-9 col-md-9 m-b30 dis-tbl text-justify">
                                        <div className="dis-tbl-cell">
                                            <h3 className="box-title m-b0">Dr. Hassan Lami<span className="bg-primary"></span></h3>
                                            <p className='font-15 font-weight-600 text-primary'>Board Director</p>
                                            <p className="font-16">Dr. Hassan Lami started his academic journey by completing a Bachor of Optometry and Bachelor of Advanced Science Honours. He pursued his studies further by completing a Doctor of Medicine and has been working as a medical doctor.</p>
                                            <p className="font-16">He commenced his religious studies in 2015, going on to accomplish a  Bachelor of Islamic studies at Al-Moustafa University in Qom. Currently, he is doing his Master of Philosophy of Ethics at Al-Moustafa University.</p>
                                            <p className="font-16">He has been an active member of the community since 2010 by teaching at a number of Saturday schools, giving lectures and courses at different centres, and establishing the Eqraa Saturday school in the Liverpool LGA. He was previously the Islamic curriculum coordinator at Bellfield College and is currently on the Illuminate Australia Board, facilitating the establishment of full-time Shia schools across different states in Australia.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row dzseth  m-b30">
                                    <div className="col-lg-3 col-md-3 m-b30 about-img ">
                                        <img src={pic3} data-tilt alt="Dr. Mohammad Qais Hassan" />
                                    </div>
                                    <div className="col-lg-9 col-md-9 m-b30 dis-tbl text-justify">
                                        <div className="dis-tbl-cell">
                                            <h3 className="box-title m-b0">Dr. Mohammad Qais Hassan<span className="bg-primary"></span></h3>
                                            <p className='font-15 font-weight-600 text-primary'>Digital Media</p>
                                            <p className="font-16">Dr. Mohammad Qais Hassan is a Doctor of Traditional Chinese Medicine and Acupuncture. He completed his studies at Western Sydney University in 2020 and has since founded a successful acupuncture clinic in Western Sydney, offering expert acupucnture and herbal medicine services. As a former Personal Trainer, Dr. Mohammad has a lifelong passion and interest in physical activity, healthy lifestyle and emotional upliftment.</p>
                                            <p className="font-16">Beyond his medical expertise, Dr. Mohammad is a dynamic motivational speaker, poet, and skilled videographer. As a proud Shia Australian youth, he is involved across multiple Islamic Centres and Organisations in Sydney, seeking to inspire others towards physical vitality and spiritual growth through his multifaceted talents.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row dzseth  m-b30">
                                    <div className="col-lg-3 col-md-3 m-b30 about-img ">
                                        <img src={pic4} data-tilt alt="Mustafa Alnadaf" />
                                    </div>
                                    <div className="col-lg-9 col-md-9 m-b30 dis-tbl text-justify">
                                        <div className="dis-tbl-cell">
                                            <h3 className="box-title m-b0">Mustafa Alnadaf<span className="bg-primary"></span></h3>
                                            <p className='font-15 font-weight-600 text-primary'>Videography and Photography</p>
                                            <p className="font-16">Mustafa Alnadaf is currently pursuing a Bachelor's degree in Electrical Engineering.</p>
                                            <p className="font-16">Mustafa has extensive expertise in media production and generously donates his time and skills to many community causes.</p>
                                            <p className="font-16">He is also an educator in Islamic studies and Arabic language at Ahl Al Bait Islamic Centre, as well as Al Kawthar school.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row dzseth  m-b30">
                                    <div className="col-lg-3 col-md-3 m-b30 about-img ">
                                        <img src={pic5} data-tilt alt="Dr. Hamed Faizi" />
                                    </div>
                                    <div className="col-lg-9 col-md-9 m-b30 dis-tbl text-justify">
                                        <div className="dis-tbl-cell">
                                            <h3 className="box-title m-b0">Dr. Hamed Faizi<span className="bg-primary"></span></h3>
                                            <p className='font-15 font-weight-600 text-primary'>PR and Advocacy</p>
                                            <p className="font-16">Dr. Hamed has been involved in various social activities, ranging from organising educational, sporting, and cultural events, to public speaking and community engagement aimed at raising awareness on a variety of issues. His professional career spans over nine years of practice as a civil engineering consultant, working on a range of infrastructure projects across multiple continents. Currently, Hamed works as a Research Assistant at the University of Sydney. He is also in the final stage of his PhD, focusing on a novel implementation of Artificial Intelligence in civil engineering practice to enhance the operational efficiency and environmental sustainability of infrastructure projects.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default Team;