import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Header from './../../Layout/Header6';
import Footer from './../../Layout/Footer1';
import PageTitle from './../../Layout/PageTitle';
//import TestimonialCarousel from './../../Element/TestimonialCarousel';

//Images
import  about9 from './../../../images/about/about.png';
import bg2 from './../../../images/background/bg-map.jpg';
import services1 from './../../../images/our-services/mission.jpg';
import services2 from './../../../images/our-services/vision.jpg';

import bnr from './../../../images/banner/bnr2.jpg';

class Aboutus1 extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='About Us' activeMenu='About Us' />
					</div>	
                    <div className="content-block">                        					
						<VisionBlog />
						<div className="section-full content-inner-2">
                            <div className="container">
                                <div className="row ">
                                    <div className="col-lg-5 col-md-4 about-img">
                                        <img src={about9} data-tilt alt="About Us" />
                                    </div>
                                    <div className="col-lg-7 col-md-8">
                                        <div className="abuot-box left row m-lr0 ">
                                            <div class="col-lg-12">
                                                <h4 className='text-gray-dark'>ABOUT ILLUMINATE AUSTRALIA</h4>
                                                <p className="m-b5">Illuminate Australia was founded with one purpose - to build a space for Australian Muslim students to grow, learn and create with each passing day. Through a unique teaching approach and truly passionate staff, we help students develop academically and personally to the highest level.</p>
                                                <p className="m-b5">We invite you to explore our site and discover the academics and community Illuminate Australia provides to each and every student. Get in touch with us today!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>	
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
function VisionBlog(){
	return(
		<>
			<div className="section-ful our-about-info content-inner-1 " style={{ backgroundImage: "url(" + bg2 + ")",backgroundPosition:"center" ,backgroundRepeat:"no-repeat" }}>
				<div className="container">				
                    <div className="row dzseth column-reverse m-b30">
						<div className="col-lg-6 col-md-12 dis-tbl text-justify">
							<div className="dis-tbl-cell">
								<h3 className="box-title">Vision<span className="bg-primary"></span></h3>
                                <p className="font-16">The vision of Illuminate Australia is to provide Muslim children with the opportunity to receive a high quality education in an environment that is inspired and instructed by Shia Muslim values and culture. </p>
                                <p className="font-16">We want our community to benefit from successive generations of morally upright students who can contribute to the wider Australian society with the two wings of faith (Iman) and knowledge (I’lm). </p>
							</div>
						</div>
						<div className="col-lg-6 col-md-12 about-img ">
							<img src={services2} data-tilt alt="" />
						</div>
					</div>	
					<div className="row dzseth">
						<div className="col-lg-6 col-md-12 m-b30 about-img ">
							<img src={services1} data-tilt alt="" />
						</div>
						<div className="col-lg-6 col-md-12 m-b30 dis-tbl text-justify">
							<div className="dis-tbl-cell">
								<h3 className="box-title">Mision<span className="bg-primary"></span></h3>
								<p className="font-16">We estimate that there are 25,000 Shia Muslim school aged children across Australia. Our mission is to provide as many of these students with easy access to such a schooling environment by establishing independent not-for-profit, faith-based schools aligned with Islamic values in all the major cities in Australia.</p>
							</div>
						</div>
					</div>					
				</div>
			</div>
		</>
	)
}	

export {VisionBlog};
export default Aboutus1;