import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';

import about1 from './../../images/our-services/pic1.jpg';
import about2 from './../../images/our-services/pic2.jpg';
import about3 from './../../images/our-services/pic5.jpg';
import about4 from './../../images/our-services/pic4.jpg';

const Index4Tab = () =>{
	const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }	
	return(
		<>
			<div className="dez-tabs">
				<div className="m-auto text-center tab-market">
					<ul className="nav nav-tabs">				
						<li>
							<Link to ={"#"} className= {classnames({ active : activeTab === '1'}) + ''} onClick={() => { toggle('1'); }}>
								<i className="ti-user"></i><span className="title-head">Character Taught</span>
							</Link>
						</li>
						<li>
							<Link to ={"#"} className= {classnames({ active : activeTab === '2'}) + ''} onClick={() => { toggle('2'); }}>
								<i className="ti-ruler-pencil"></i><span className="title-head">Character Caught</span>
							</Link>
						</li>
						<li>
							<Link to ={"#"} className= {classnames({ active : activeTab === '3'}) + ''} onClick={() => { toggle('3'); }}>
								<i className="ti-comments"></i><span className="title-head">Community Engagement</span>
							</Link>
						</li>
					</ul>
				</div>
				<div className="tab-content m-t50 text-white">
					<TabContent activeTab={activeTab}>
						<TabPane tabId="1">
							<div id="web-design" className="tab-pane show fade">
								<div className="row">
									<div className="col-lg-8 m-b30">
										<div className="box">
											<h3 className="font-40"><span className="font-weight-300">Character</span> Taught.</h3>
											<p>Here, we are facilitators, distilling the elixir of Islamic values, beliefs and ethics, in the footsteps of the Prophet and his luminous family. This branch of Illuminate has three aspects:</p>
											<ul className='list-checked black'>
												<li><span className='font-weight-bold'>Prayer Time:</span> Conveying the fundamental tenets of Islam.</li>
												<li><span className='font-weight-bold'>Illuminations Curriculum:</span> A groundbreaking, research-backed approach that integrates Islamic character education using modern pedagogical theories, positive psychology, and the ethics of philosophy.</li>
												<li><span className='font-weight-bold'>Character Journal:</span> A novel feature of our prototype that nurtures and fosters students' emotional, cognitive, and spiritual well-being through reflective journaling.</li>
											</ul>										
										</div>
									</div>
									<div className="col-lg-4 margin-v-auto">
										<img src={about3} className="radius-sm" alt="" />
									</div>
								</div>
							</div>
						</TabPane>	
						<TabPane tabId="2">
							<div id="web-development" className="tab-pane show fade">
								<div className="row">
									<div className="col-lg-4 m-b30">
										<img src={about2} className="radius-sm" alt="" />
									</div>
									<div className="col-lg-8">
										<div className="box">
											<h3 className="font-40"><span className="font-weight-300">Character</span> Caught</h3>
											<p>This component refers to the general environment of the schools includes aspects such as nurturing modesty and respect within the school, as well as a focus on engaging students through special events in the Islamic calendar.</p>
										</div>
									</div>
								</div>
							</div>
						</TabPane>	
						<TabPane tabId="3">
							<div id="mobile-app" className="tab-pane active show fade">
								<div className="row">
									<div className="col-lg-8 m-b30">
										<div className="box">
											<h3 className="font-40"><span className="font-weight-300">Community</span> Engagement.</h3>
											<p>This facet involves working closely with parents and the wider community. </p>
											<p>We believe parents are the true educators, and we aim to work closely with them in nurturing students. </p>
											<p>It also involves the establishment of meaningful connections with community leaders and centers.</p>
										</div>
									</div>
									<div className="col-lg-4">
										<img src={about1} className="radius-sm" alt="" />
									</div>
								</div>
							</div>
						</TabPane>	
						<TabPane tabId="4">
							<div id="internet-marketing" className="tab-pane show fade">
								<div className="row">
									<div className="col-lg-4 m-b30">
										<img src={about4} className="radius-sm" alt="" />
									</div>
									<div className="col-lg-8">
										<div className="box">
											<h3 className="font-40"><span className="font-weight-300">Make business with us</span> & become beautiful</h3>
											<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text</p>
											<Link to={"#"} className="site-button radius-xl button-md">Read More</Link>
										</div>
									</div>
								</div>
							</div>
						</TabPane>	
					</TabContent>		
				</div>
			</div>	
		</>
	)
} 

export default Index4Tab;