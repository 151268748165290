import React,{Component} from 'react';

class ProjectCounter extends Component{
	render(){
		return(
			<div className="container">
                <div className="row m-b30">
                    <div className="col-lg-4 col-md-12 align-self-center">
                        <h5>Who We Are</h5>
                        <h2 className="font-weight-700">An educational initiative to build faith-based schools.</h2>
                    </div>
                    <div className="col-lg-8 col-md-12">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                                    <svg className="radial-progress" data-percentage="90" viewBox="0 0 80 80">
                                        <circle className="incomplete" cx="40" cy="40" r="35"></circle>
                                        <circle className="complete" cx="40" cy="40" r="35" style={{"strokeDashoffset":"39.58406743523136"}}></circle>
                                        <text className="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">3</text>
                                    </svg>
                                    <div className="icon-content">
                                        <h5 className="font-weight-500">Project Completed</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                                    <svg className="radial-progress" data-percentage="49" viewBox="0 0 80 80">
                                        <circle className="incomplete" cx="40" cy="40" r="35"></circle>
                                        <circle className="complete" cx="40" cy="40" r="35" style={{"strokeDashoffset":"39.58406743523136"}}></circle>
                                        <text className="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">4</text>
                                    </svg>
                                    <div className="icon-content">
                                        <h5 className="font-weight-500">Ongoing Projects</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                                    <svg className="radial-progress" data-percentage="65" viewBox="0 0 80 80">
                                        <circle className="incomplete" cx="40" cy="40" r="35"></circle>
                                        <circle className="complete" cx="40" cy="40" r="35" style={{"strokeDashoffset":"39.58406743523136"}}></circle>
                                        <text className="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">5</text>
                                    </svg>
                                    <div className="icon-content">
                                        <h5 className="font-weight-500">Upcoming Projects</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}
export default ProjectCounter;