import React, {Component} from 'react';
import { BrowserRouter, Route, Routes, Switch } from 'react-router-dom';

import Index4 from './Pages/Index4';
import Aboutus1 from './Pages/Aboutus/Aboutus1';
import OurProjects from './Pages/OurProjects';
import ProjectDetails from './Pages/ProjectDetails';
import Bellfield from './Pages/Projects/Bellfield';
import Playford from './Pages/Projects/Playford';

import ProjectManagement from './Pages/ProjectManagement';
import Team from './Pages/Team';
import Contact from './Pages/Contact';
import Service from './Pages/Service/Service';
import ServicesDetails from './Pages/Service/ServicesDetails';
import BlogListRightSidebar from './Pages/BlogList/BlogListRightSidebar';
import BlogDetails from './Pages/BlogDetail/BlogDetails';
import ProtfolioFullWidth from './Pages/Protfolio/ProtfolioFullWidth';




import ScrollToTop from './Element/ScrollToTop';
import Blacktown from './Pages/Projects/Blacktown';
import Victoria from './Pages/Projects/Victoria';
import Perth from './Pages/Projects/Perth';
import JobOpportunities from './Pages/JobOpprtunities';
import Statistics from './Pages/Statistics';
import DonationPortal from './Pages/DonationPortal';
import Fundraising from './Events/Fundraising';

class Markup extends Component{
	render(){
		return(
			<BrowserRouter basename="/">
                <div className="page-wraper">
                    <Routes>
                        <Route path='/' exact element={<Index4 />} />                        
                        <Route path='/about' exact element={<Aboutus1 />} />                        
                        <Route path='/statistics' exact element={<Statistics />} />                        
                        <Route path='/donation-portal' exact element={<DonationPortal />} />                        
                        <Route path='/projects' exact element={<OurProjects />} />
                        <Route path='/nsw' exact element={<ProjectDetails />} />
                        <Route path='/bellfield' exact element={<Bellfield />} />
                        <Route path='/playford' exact element={<Playford />} />
                        <Route path='/blacktown' exact element={<Blacktown />} />
                        <Route path='/victoria' exact element={<Victoria />} />
                        <Route path='/perth' exact element={<Perth />} />
                        <Route path='/our-people' exact element={<Team />} />
                        <Route path='/contact' exact element={<Contact />} />
                        <Route path='/service' exact element={<Service />} />
                        <Route path='/services-details' exact element={<ServicesDetails />} />
                        <Route path='/blog' exact element={<BlogListRightSidebar />} />
                        <Route path='/blog-details' exact element={<BlogDetails />} />
                        <Route path='/portfolio' exact element={<ProtfolioFullWidth />} />
                        <Route path='/our-history' exact element={<ProjectManagement />} />						
                        <Route path='/job-opportunities' exact element={<JobOpportunities />} />	
                        <Route path="/events/fundraising-dinner-04-feb-2024" exact element={<Fundraising />} />
                    </Routes>
                </div>
				<ScrollToTop />
            </BrowserRouter>	
		)
	}
	
}

export default Markup;