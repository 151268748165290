import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Masonry from 'react-masonry-component';
//Images
import grid1 from './../../images/events/thumb/fundraising-dinner.jpg';

const postBlog = [
    { 
        images: grid1, 
        title: "FUNDRAISING DINNER", 
        description: "Join us to partake in the mission of building a fruitful legacy for the future generations of our community scheduled on 04th Feb 2024 at 06:00 PM.",
        author: "Admin",
        eventdate: "04 Feb 2024",
        url: "/events/fundraising-dinner-04-feb-2024"
    }, 
];

// Masonry section
const masonryOptions = {
    transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }
// Masonry section end
function EventItems2()
{
    return(
        <>
            <div className="widget recent-posts-entry">
                <h5 className="widget-title style-1">Recent Posts</h5>
                <div className="widget-post-bx">
                    {postBlog.map((item,index)=>(
                        <div className="widget-post clearfix" key={index}>
                            <div className="dlab-post-media"> <img src={item.images} width="200" height="143" alt="" /> </div>
                            <div className="dlab-post-info">
                                <div className="dlab-post-header">
                                    <h6 className="post-title"><Link to={item.url}>{item.title}</Link></h6>
                                </div>
                                <div className="dlab-post-meta">
                                    <ul>
                                        <li className="post-author">{item.author}</li>
                                        <li className="post-comment">{item.eventdate}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
class EventItems extends Component {
    render() {
        return (
            <>
                <div className="dlab-blog-grid-3 " id="masonry" >
                    <Masonry
                        className={'my-gallery-class'} // default ''
                        options={masonryOptions} // default {}
                        disableImagesLoaded={false} // default false
                        updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                        imagesLoadedOptions={imagesLoadedOptions} // default {}
                    >
                        {postBlog.map((data, index) => (
                            <div className="post card-container col-lg-6 col-md-6 col-sm-12 col-xs-12" key={index}>
                                <div className="blog-post blog-grid blog-rounded blog-effect1">
                                    <div className="dlab-post-media dlab-img-effect "><Link to={data.url}><img src={data.images} alt={data.title} /></Link> </div>
                                    <div className="dlab-info p-a20 border-1">
                                        <div className="dlab-post-title ">
                                            <h4 className="post-title"><Link to={data.url}>{data.title}</Link></h4>
                                        </div>
                                        <div className="dlab-post-meta">
                                            <ul className="d-flex align-items-center">
                                                <li className="post-date"> <i className="fa fa-calendar"></i><strong>{data.eventdate}</strong> </li>
                                                <li className="post-author"><i className="fa fa-user"></i>By <Link to={"#"}>{data.author}</Link> </li>
                                            </ul>
                                        </div>
                                        <div className="dlab-post-text">
                                            <p>{data.description}</p>
                                        </div>
                                        <div className="dlab-post-readmore blog-share">
                                            <Link to={data.url} title="READ MORE" rel="bookmark" className="site-button outline outline-1">READ MORE
                                                <i className="fa fa-long-arrow-right ml-1"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Masonry>
                </div>
            </>
        )
    }
}
export {EventItems2};
export default EventItems;