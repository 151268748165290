import React, { Component } from 'react';
import Header from './../../Layout/Header6';
import Footer from './../../Layout/Footer1';
import PageTitle from './../../Layout/PageTitle';


//Images 
//import bnr1 from './../../../images/banner/bnr2.jpg';
import ProjectCounter from '../../Element/ProjectCounter';
import ProjectCarousel from '../../Element/ProjectCarousel';
import pic1 from './../../../images/project/victoria/vic-1.jpg';
import pic2 from './../../../images/project/victoria/vic-2.jpg';
import pic3 from './../../../images/project/victoria/vic-3.jpg';
import pic4 from './../../../images/project/victoria/vic-4.jpg';

import gal1 from './../../../images/gal/mooroopna/vic-1.jpg';
import gal2 from './../../../images/gal/mooroopna/vic-2.jpg';
import gal3 from './../../../images/gal/mooroopna/vic-3.jpg';
import gal4 from './../../../images/gal/mooroopna/vic-4.jpg';
import gal5 from './../../../images/gal/mooroopna/vic-1.jpg';
import gal6 from './../../../images/gal/mooroopna/vic-2.jpg';
import gal7 from './../../../images/gal/mooroopna/vic-3.jpg';
import gal8 from './../../../images/gal/mooroopna/vic-4.jpg';
import gal9 from './../../../images/gal/mooroopna/vic-1.jpg';
import gal10 from './../../../images/gal/mooroopna/vic-2.jpg';
import gal11 from './../../../images/gal/mooroopna/vic-3.jpg';
import gal12 from './../../../images/gal/mooroopna/vic-4.jpg';


import ProjectGallery from '../../Element/ProjectGallery';

const gallerBox = [
	{  Large: gal1 ,title: 'Victoria Image' ,desc: 'Victoria Image' ,  }, 
    {  Large: gal2 ,title: 'Victoria Image' ,desc: 'Victoria Image' ,  }, 
    {  Large: gal3 ,title: 'Victoria Image' ,desc: 'Victoria Image' ,  },
	{  Large: gal4 ,title: 'Victoria Image' ,desc: 'Victoria Image' ,  }, 
    {  Large: gal5 ,title: 'Victoria Image' ,desc: 'Victoria Image' ,  }, 
    {  Large: gal6 ,title: 'Victoria Image' ,desc: 'Victoria Image' ,  },
	{  Large: gal7 ,title: 'Victoria Image' ,desc: 'Victoria Image' ,  },
    {  Large: gal8 ,title: 'Victoria Image' ,desc: 'Victoria Image' ,  }, 
    {  Large: gal9 ,title: 'Victoria Image' ,desc: 'Victoria Image' ,  },
	{  Large: gal11 ,title: 'Victoria Image' ,desc: 'Victoria Image' , }, 
    {  Large: gal10 ,title: 'Victoria Image' ,desc: 'Victoria Image' , }, 
    {  Large: gal12 ,title: 'Victoria Image' ,desc: 'Victoria Image' , }, 
];

const carouselImg =[
	{pic: pic1},
	{pic: pic2},
	{pic: pic3},
	{pic: pic4},
]


class Victoria extends Component {
    /* componentDidMount(){
        window.abc();
    } */
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + pic4 + ")" }}>
                        <PageTitle motherMenu='Upcoming School In Victoria, Australia' activeMenu='Victoria School' />  
                    </div>
                    <div className="content-block">
                        <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="row m-b40">
                                    <div className="col-lg-6 col-md-6 m-b20">
                                        <h2 className="m-b10 m-t0">Upcoming School In Victoria, Australia</h2>
                                        <p className="m-b10">We are going to start a new College very soon in Victoria, Australia.</p>
                                        {/* <Link to={"#"} className="site-button radius-xl outline">Read More <i className="fa fa-long-arrow-right"></i></Link> */}
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <ProjectCarousel imglist={carouselImg} />
                                    </div>
                                </div>
                                <div className="row m-b50">
                                    <div className="col-md-6 m-b20">
                                        <div className="p-a30 bg-gray">
                                            <h6>Project Name: <span className="m-l10 font-weight-300 text-gray-dark">Mooroopna College</span></h6>
                                            <h6>Expected School Opening  Date: <span className="m-l10 font-weight-300 text-gray-dark"> 2026</span></h6>                                            
                                            <h6>Project Url: <span className="m-l10 font-weight-300 text-gray-dark">TBC</span></h6>
                                            <h6>Email: <span className="m-l10 font-weight-300 text-gray-dark">enquiries@illuminateaust.org.au</span></h6>
                                            <h6>Phone: <span className="m-l10 font-weight-300 text-gray-dark">0433 291 188</span></h6>
                                        </div>
                                    </div>
                                    <div className="col-md-6 align-self-center">
                                        <h5 className="text-black fw5 m-b10">An educational initiative to build faith-based schools to teach wisdom through faith and knowledge.</h5>
                                        <p className="m-b0">We want to raise a generation of morally upright students who can contribute to the wider Australian society with the two wings of faith (Iman) and knowledge (I’lm). Our mission is to establish Independent Not for Profit faith-based Schools aligned with Islamic values in all major cities of Australia.</p>
                                    </div>
                                </div>
                            </div>
                            <ProjectCounter />
                            <ProjectGallery imglist={gallerBox} />
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default Victoria;