import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class Footer1 extends Component {
    render() {
        return (
            <>
                <footer className="site-footer">
                    <div className="footer-top bg-primary">
                        <div className="container" >
                            <div className="row">
                                <div className="col-5 col-lg-2 col-md-6 col-sm-6 footer-col-4">
                                    <div className="widget widget_services border-0">
                                        <h5 className="m-b30 text-white text-uppercase">Company</h5>
                                        <ul>
											<li><Link to={"./about"}>Mission and Vision </Link></li>                                            
                                            <li><Link to={"./contact"}>Contact Us</Link></li>
                                            <li><Link to={'./blog'}>Blogs</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-7 col-lg-2 col-md-6 col-sm-6 footer-col-4">
                                    <div className="widget widget_services border-0">
                                        <h5 className="m-b30 text-white text-uppercase">Useful Link</h5>
                                        <ul>
                                            <li><a target="_blank" rel="noopener noreferrer" href="https://www.abs.gov.au/media-centre/media-releases/2021-census-shows-changes-australias-religious-diversity">Australia’s religious diversity</a></li>
                                            <li><a target="_blank" rel="noopener noreferrer" href="https://www.unisa.edu.au/contentassets/4f85e84d01014997a99bb4f89ba32488/australian-muslims-final-report-web-nov-26.pdf">Australian Muslim Report</a></li>
                                            <li><a target="_blank" rel="noopener noreferrer" href="https://www.pewresearch.org/religion/2009/10/07/mapping-the-global-muslim-population/">Global Muslim Population Mapping</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-4">
                                    <div className="widget widget_getintuch">
                                        <h5 className="m-b30 text-white text-uppercase">Contact us</h5>
                                        <ul>
                                            <li><i className="ti-mobile"></i><strong>Phone</strong>0433 291 188 (24/7 Support Line)</li>
                                            <li><i className="ti-email"></i><strong>Email</strong>enquiries@illuminateaust.org.au</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-4 ">
                                    <div className="widget">
                                        <h5 className="m-b30 text-white">Subscribe To Our Newsletter</h5>
                                        <p className="m-b20">If you have any questions, you can contact with us so that we can give you a satisfying answer. Subscribe to our newsletter to get our latest products.</p>
                                        <div className="subscribe-form m-b20">
                                            <form className="dzSubscribe" action="script/mailchamp.php" method="post">
                                                <div className="dzSubscribeMsg"></div>
                                                <div className="input-group">
                                                    <input name="dzEmail" required="required" className="form-control" placeholder="Your Email Id" type="email" />
                                                    <span className="input-group-btn">
                                                        <button name="submit" value="Submit" type="submit" className="site-button text-uppercase">Subscribe</button>
                                                    </span>
                                                </div>
                                            </form>
                                        </div>
                                        <ul className="list-inline m-a0">
                                            <li><a href="https://www.facebook.com/profile.php?id=61551326008561" rel='noopener noreferrer' target='_blank' className="site-button facebook circle mr-1"><i className="fa fa-facebook"></i></a></li>
                                            <li><a href='https://www.instagram.com/illuminate_australia/' rel='noopener noreferrer' target='_blank' className="site-button instagram circle mr-1"><i className="fa fa-instagram"></i></a></li>
                                            <li><a href="https://www.linkedin.com/company/illuminate-australia/" target='_blank' rel="noopener noreferrer" className="site-button linkedin circle mr-1"><i className="fa fa-linkedin"></i></a></li>                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom bg-primary">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 text-left "> <span className='font-weight-bold'>Copyright © 2023 Illuminate Australia</span></div>
                                <div className="col-lg-6 col-md-6 col-sm-6 text-right ">
                                <p className='m-0 font-weight-bold'>Design and Developed By <a className='text-dark' href="https://xpansion.com.au/" target='_blank'>Xpansion Technologies</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

export default Footer1;