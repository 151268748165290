import React, { useState} from 'react';
// import { Link } from 'react-router-dom';
import Header from './../Layout/Header6';
import Footer from './../Layout/Footer1';



export default function Contact() { 
    const [disabled, setDisabled] = useState(false);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [comments, setComments] = useState("");

    //   Form validation state
    const [errors, setErrors] = useState({});
    //   Setting button text on form submission
    const [buttonText, setButtonText] = useState("Send");
    const [resturnMsg, setresturnMsg] = useState("");
    // Setting success or failure messages states
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showFailureMessage, setShowFailureMessage] = useState(false);
    const handleValidation = () => {
        let tempErrors = {};
        let isValid = true;
    
        if (name.length <= 0) {
          tempErrors["name"] = true;
          isValid = false;
        }
        if (phone.length <= 0) {
            tempErrors["phone"] = true;
            isValid = false;
        }
        if (email.length <= 0) {
          tempErrors["email"] = true;
          isValid = false;
        }
        if (subject.length <= 0) {
          tempErrors["subject"] = true;
          isValid = false;
        }
        if (comments.length <= 0) {
          tempErrors["comments"] = true;
          isValid = false;
        }
    
        setErrors({ ...tempErrors });
        setShowSuccessMessage(false);
        setShowFailureMessage(false);
        //console.log("errors", errors);
        return isValid;
      };
      const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Hello");
        let isValidForm = handleValidation();
    
        if (isValidForm) {
          setButtonText("Sending");
          setDisabled(true);
          await fetch("https://demo.xpansion.com.au/api/illuminate/contact.php", {
            body: JSON.stringify({
              email: email,
              subject: subject,
              name: name,
              phone: phone,
              comments: comments,
            }),
            method: "POST",
          }).then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((result) => {
            if (result.success) {
                setShowSuccessMessage(true);
                setShowFailureMessage(false);
                setName('');
                setEmail('');
                setSubject('');
                setComments('');
            } else {
                setShowSuccessMessage(false);
                setShowFailureMessage(true);                
            }
            setresturnMsg(result.message);
            setButtonText("Send");
            setDisabled(false);
          });
        }
        //console.log(name, email, subject, comments);
      };
    return (
        <>
            <Header />
            <div className="full-section">
                {/* <!-- inner page banner --> */}
                <div className="dlab-bnr-inr overlay-primary-dark contact-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-xs-4">
                                <div className="row text-white">
                                    <div className="col-lg-12 col-md-6 m-b30" >
                                        <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                            <div className="icon-content">                                                    
                                                <h5 className="dlab-tilte">
                                                    <span className="icon-sm text-warning text-left">
                                                        <i className="ti-alarm-clock"></i> </span> Office Hours
                                                </h5>
                                                <p className="m-b0">Mon To Sat - 08.00-18.00</p>
                                                <p>Sunday - Close</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-6 m-b30 ">
                                        <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                            <div className="icon-content">
                                                <h5 className="dlab-tilte">
                                                    <span className="icon-sm text-warning text-left"><i className="ti-email"></i></span>
                                                    E-mail
                                                </h5>
                                                <p className="m-b0">info@illuminateaust.org.au</p>
                                                <p className="m-b0">enquiries@illuminateaust.org.au</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-6 m-b30 ">
                                        <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                            <div className="icon-content">
                                                <h5 className="dlab-tilte">
                                                    <span className="icon-sm text-warning text-left"><i className="ti-mobile"></i></span>
                                                    Phone Numbers
                                                </h5>
                                                <div className='d-flex justify-content-between'>
                                                    <p className="m-b0">Hajj Ali Hossain</p>
                                                    <a className='text-white' href='tel:+61411261597'>0411 261 597</a>
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <p className="m-b0">Dr Hassan Lami</p>
                                                    <a className='text-white' href='tel:+61433291188'>0433 291 188</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-xs-8">
                                <form className="inquiry-form dzForm" method="post" name="myForm" onSubmit={handleSubmit} >
                                    <div className="dzFormMsg"></div>
                                    <h3 className="box-title m-t0 m-b10">Let's Convert Your Idea into Reality <span className="bg-primary"></span></h3>                                        
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group  mb-0">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="ti-user text-primary"></i></span>
                                                    <input name="name" id="name" type="text" className="form-control" placeholder="Your Name" value={name} onChange={(e) =>  setName(e.target.value)} />                                                    
                                                </div>                                                
                                                {errors?.name && (
                                                    <p className="text-red">Please Enter Name.</p>
                                                )}
                                            </div>                                            
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="ti-mobile text-primary"></i></span>
                                                    <input name="phone" id="phone" type="text" className="form-control" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />                                                    
                                                </div>                                                
                                                {errors?.phone && (
                                                    <p className="text-red">Please Enter Phone.</p>
                                                )}
                                            </div>                                            
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="ti-email text-primary"></i></span>
                                                    <input name="email" id="email" type="email" className="form-control" value={email} placeholder="Your Email Id" onChange={(e) => setEmail(e.target.value)} />                                                    
                                                </div>
                                                {errors?.email && (
                                                    <p className="text-red">Please Enter Email.</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="ti-check-box text-primary"></i></span>
                                                    <input name="subject" type="text" className="form-control" value={subject} placeholder="Subject" onChange={(e) => setSubject(e.target.value)} />                                                    
                                                </div>
                                                {errors?.subject && (
                                                    <p className="text-red">Please Enter Subject Line.</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="ti-agenda text-primary"></i></span>
                                                    <textarea name="comments" id="comments" rows="4" value={comments} className="form-control" placeholder="Tell us about your project or idea" onChange={(e) => setComments(e.target.value)}></textarea>                                                    
                                                </div>
                                                {errors?.comments && (
                                                    <p className="text-red">Please Enter Your Message.</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="text-left">
                                                {showSuccessMessage && (
                                                <p className="text-green-500 font-semibold text-sm my-2">
                                                    {resturnMsg}
                                                </p>
                                                )}
                                                {showFailureMessage && (
                                                <p className="text-red">
                                                    {resturnMsg}
                                                </p>
                                                )}
                                            </div>
                                            <button name="send" id="submit" type="submit" value="Submit" className="site-button button-lg" disabled={disabled}> <span>{buttonText}</span> </button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- inner page banner END --> */}
            </div>

            <Footer />
        </>
    )
}