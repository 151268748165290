import React, { Component } from 'react';
import EventItems from './EventItems';

class EventsGrid extends Component {
    render() {
        return (
            <>
                <div className="section-full bg-white content-inner explore-projects">
                    <div className="container">
                        <div className="section-content">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 section-head text-center">
                                    <h2 className="m-b0 font-40"><span className="font-weight-400">Explore</span> Events</h2>
                                    <p className="m-b0">Explore Our Upcoming Events</p>
                                </div>
                            </div>
                            {/* <!-- blog post Carousel with no margin --> */}
                            <div className="">
                                <EventItems />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default EventsGrid;