import React,{Component} from 'react';
import { EventItems2 } from './EventItems';
import { ProjectSidebar } from '../Pages/OurProjects';

class Sidebar extends Component{
	render(){
		return(
			<>
				<aside className="side-bar">					
					<EventItems2 />

					<div className="widget widget-newslatter">
						<h5 className="widget-title style-1">Newsletter</h5>
						<div className="news-box">
							<p>Enter your e-mail and subscribe to our newsletter.</p>
							<form className="dzSubscribe" action="script/mailchamp.php" method="post">
								<div className="dzSubscribeMsg"></div>
								<div className="input-group">
									<input name="dzEmail" required="required" type="email" className="form-control" placeholder="Your Email" />
									<button name="submit" value="Submit" type="submit" className="site-button btn-block radius-no">Subscribe Now</button>
								</div>
							</form>
						</div>
					</div>	
					<ProjectSidebar />
				</aside>
			</>
		)
	}
}
export default Sidebar;