import React, { Component } from 'react';
import Header from './../Layout/Header6';
import Footer from './../Layout/Footer1';
import PageTitle from './../Layout/PageTitle';

//Images
import  donation from './../../images/donations.jpg';
import bg1 from './../../images/background/bg1.png';

import bnr from './../../images/banner/donation-bcm.jpg';

class DonationPortal extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='How You Can Donate?' activeMenu='Donation Portal' />
					</div>	
                    <div className="content-block">
                        <div className="section-full bg-white content-inner" style={{ backgroundImage: "url(" + bg1 + ")" }}>                        
                            <div className="container">
                            <div className="our-featured">
                                    <div className="setResizeMargin">
                                        <div className="row m-a0 d-flex align-items-center">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="section-head title-bx">
                                                    <h2>How you can Donate?</h2>
                                                    <h3>Your financial support in two forms:</h3>
                                                    <ul className='list-checked primary'>
                                                        <li>Donations/charities</li>
                                                        <li>No interest loans (2-5 years)</li>                                                        
                                                    </ul>                                     
                                                    <p><b>Who is he that will lend Allah a good loan! He will multiply it many multiples! 2:245</b></p>               
                                                    <h2>Donation</h2> 
                                                    <ul className='list-checked primary'>
                                                        <li>No interest loan: For further information and contract please contact 0433291188.</li>
                                                        <li>Charity: Kindly transfer to the following account. If you require a receipt please contact 0433291188.</li>
                                                    </ul>
                                                    <p><b>Account Name:</b> Illuminate</p>
                                                    <p><b>Bank Account Number:</b> 20095795</p>
                                                    <p><b>BSB:</b> 062-000</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 text-right">
                                                <div className="feature-img-bx" >
                                                    <img src={donation} alt="" className="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                            </div>                            
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default DonationPortal;