import React, {Component,Fragment } from 'react';
import { Link } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import {SRLWrapper, useLightbox} from 'simple-react-lightbox'; 

//Light Gallery on icon click 
const Iconimage = props => {
	const { openLightbox } = useLightbox()
	return (
    <Link  to={"#"} onClick={() => openLightbox(props.imageToOpen)} className="mfp-link portfolio-fullscreen" >
      <i className="ti-fullscreen icon-bx-xs"></i>
    </Link>
  )
}

class ProjectGallery extends Component{
	render(){
		return(
			<Fragment>	
				<div className="page-content bg-white">
					<div className="content-block">
						<div className="section-full content-inner-2 portfolio text-uppercase">	
							<div className="container">
								<div className="row">			                                    					
									<div className="col-md-12">
                                        <h3>Project Gallery</h3>										
									</div>                                    
								</div>	
                                <GalleryGrid imglist={this.props.imglist} />
							</div>
						</div>
					</div>	
				</div>	
			</Fragment>			
		)
	}
} 

function GalleryGrid(props){
	return(
		<>
			<SimpleReactLightbox>
				<SRLWrapper >
					<ul className="row dlab-gallery-listing gallery-grid-4 gallery mfp-gallery port-style1">
						{props.imglist.map((item, index)=>(
							<li className="web design card-container col-lg-4 col-md-6 col-sm-6 p-b20" key={index}>
								<div className="dlab-box dlab-gallery-box">
									<div className="dlab-media dlab-img-overlay1 dlab-img-effect"> 
										<img src={item.Large} alt={item.title} />
										<div className="overlay-bx">
											<div className="overlay-icon align-b text-white text-left">
												<div className="text-white text-left port-box">
													<h5>{item.title}</h5>
													<p>{item.desc}</p>
													<Iconimage />
												</div>
											</div>
										</div>
									</div>
								</div>
							</li>
						))}		
					</ul>
				</SRLWrapper >	
			</SimpleReactLightbox>
		</>
	)
}
export {GalleryGrid};
export default ProjectGallery;
